import React, { useState } from "react";
import InstaFollower from "../media/images/Insta-Followers.png";
import InstaLike from "../media/images/Insta-like.png";
import InstaShare from "../media/images/Insta-share.png";
import InstaView from "../media/images/Insta-view.png";
import InstaComment from "../media/images/Insta-comment.png";

import insta from "../media/images/icons/instagram.webp";
import InstagramPremiumFollowersFormComponent from "./form/InstagramPremiumFollowersFormComponent";
import InstagramPremiumLikesFormComponent from "./form/InstagramPremiumLikesFormComponent";
import InstagramPremiumViewsFormComponent from "./form/InstagramPremiumViewsFormComponent";
import InstagramPremiumCommentsFormComponent from "./form/InstagramPremiumCommentsFormComponent";
import InstagramPremiumPostShareFormComponent from "./form/InstagramPremiumPostShareFormComponent";
import InstagramPremiumReachFormComponent from "./form/InstagramPremiumReachFormComponent";


const InstaFormComponent = () => {

    
    const [type, setType] = useState("Followers")
  const [ShowCategory, setShowCategory] = useState(false);
 



  
  const [user, setUser] = useState({
    name: "",
    number: "",
    state: "", 
    lines: "___Auto Comment___",
    quantity: "",
    url: "",
  });


  const handleChange = (e) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;

    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };
  
  
  return (
    <div>
      
      <div className="header">
        <div className="header-col-2">
          <div className="header-col-2-category" onClick={(e) => setShowCategory(!ShowCategory)}>
          <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={insta} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    Instagram Premium&nbsp;
                    <span>{type}&nbsp;</span>
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
          </div>

          {ShowCategory ? (
            <div className="store-form-category-show-option">
              
              <div
                onClick={(e) => {
                  setType("Followers");
                  setShowCategory(false);
                }}
              >
                Instagram Premium Followers
              </div>
              
              <div
                onClick={(e) => {
                  setType("Views");
                  setShowCategory(false);
                }}
              >
                Instagram Premium Views
              </div>
              
              <div
                onClick={(e) => {
                  setType("Likes");
                  setShowCategory(false);
                }}
              >
                Instagram Premium Likes
              </div>
              
              <div
                onClick={(e) => {
                  setType("Comments");
                  setShowCategory(false);
                }}
              >
                Instagram Premium Comments
              </div>
              
              <div
                onClick={(e) => {
                  setType("Post Share");
                  setShowCategory(false);
                }}
              >
                Instagram Premium Post Share
              </div>
              
              <div
                onClick={(e) => {
                  setType("Reach");
                  setShowCategory(false);
                }}
              >
                Instagram Premium Reach
              </div>
              
              
            </div>
          ) : (
            ""
          )}
          <div className="header-col-2-heading"><>Buy Instagram Premium <span>{type} </span></>    
          </div>

          <div>
{/* 
{
    category === "auto"
 ?   <GoogleAutoReviewComponent/>
 :   category === "custom"
 ?
 <GoogleCustomReviewComponent/>
 :""
} */}


{
                type === "Followers"?
                <InstagramPremiumFollowersFormComponent/>
              :  type === "Likes"?
                <InstagramPremiumLikesFormComponent/>
              :  type === "Views"?
                <InstagramPremiumViewsFormComponent/>
              :  type === "Comments"?
                <InstagramPremiumCommentsFormComponent/>
              :  type === "Post Share"?
                <InstagramPremiumPostShareFormComponent />
              :  type === "Reach"?
                <InstagramPremiumReachFormComponent />
             :""}



            
          </div>
        </div>

        <div className="header-col-1">
            {
                type === "Followers"?
                <img src={InstaFollower} alt="logo" title="logo" />
                :type === "Likes"?
                <img src={InstaLike} alt="logo" title="logo" />
                :type === "Views"?
                <img src={InstaView} alt="logo" title="logo" />
                :type === "Comments"?
                <img src={InstaComment} alt="logo" title="logo" />
                :type === "Post Share"?
                <img src={InstaShare} alt="logo" title="logo" />
                :type === "Reach"?
                <img src={InstaView} alt="logo" title="logo" />
                :""
            }
        </div>
      </div>
    </div>
  );
};

export default InstaFormComponent;
 