import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import data from "./country.json";

import LoadingComponent from "../others/LoadingComponent";
import PaymentFormComponent from "../PaymentFormComponent";

const InstagramPremiumFollowersFormComponent = () => {
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  const [price, setPrice] = useState("");
  const [paymentForm, setPaymentForm] = useState(false);

  
  const handleclick = (e) => {
    if (user.quantity === "Instagram Premium 100 Followers") {
      setPrice("100");
    }
    if (user.quantity === "Instagram Premium 200 Followers") {
      setPrice("150");
    }
    if (user.quantity === "Instagram Premium 500 Followers") {
      setPrice("350");
    }
    if (user.quantity === "Instagram Premium 1000 Followers") {
      setPrice("700");
    }
  };

  
  const [user, setUser] = useState({
    formHeading: "Instagram Premium Followers",
    name: "",
    number: "",
    country: "",
    url: "",
    quantity: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setError('');

    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  // const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
       
    if (! user.url.startsWith("https://www.instagram.com/")) {
      setError('please enter valid url');
      setLoading(false);
      return; 
    }
    
    try {
      // const response = await fetch("http://localhost:5004/booking-form", {
      const response = await fetch("https://api.review.bhumu.com/booking-form",{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );

      if (response.ok) {
        alert("Service booked successfully! Please confirm your payment");
        // navigate("/booking-confirm");
        setPaymentForm(true)
      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }
    } catch (error) {
      alert("server error");
    }
    setLoading(false);
  };


  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

      {paymentForm ? (
        <PaymentFormComponent
          close={setPaymentForm}
          heading={user.formHeading}
          name={user.name}
          number={user.number}
          country={user.country}
          url={user.url}
          quantity={user.quantity}
          price={price}
        />
      ) : (
        ""
      )}

      <div className="header-col-2-form">
        <form onSubmit={handleSubmit}>
          <div className="header-col-2-form-col">
            <label>Full Name *</label>
            <input
              onChange={handleChange}
              name="name"
              required
              type="text"
              placeholder="Enter your name"
            />
          </div>

 
          <div className="header-col-2-form-col">
            <label>Select Target Country *</label>
            <select onChange={handleChange} name="country" required>
              <option value="" selected disabled>
                --- Select Target Country ---
              </option>
              {data.map((e) => (
                <option value={`${e.name} (${e.dial_code})`}>{e.name}</option>
              ))}
            </select>
          </div>

          {user.country && 
          <div className="header-col-2-form-col">
            <label>Mobile Number *</label>
            <div>

              <span>
                {data
                  .filter((e) => (`${e.name} (${e.dial_code})`) === user.country)
                  .map((e) => (
                    <div>
                      {e.code} ({e.dial_code})
                    </div>
                  ))}
              </span>

              <input
                onChange={handleChange}
                name="number"
                min="0"
                required
                type="number"
                placeholder="Enter your phone number"
              />
            </div>
          </div>
}

          <div className="header-col-2-form-col">
            <label>Enter URL *</label>
            <input
              onChange={handleChange}
              name="url"
              required
              type="text"
              placeholder="https://www.instagram.com/"
            />
          </div>

          <div className="header-col-2-form-col">
            <label>Select Quantity * </label>
            <select
              required
              onClick={handleclick}
              onChange={handleChange}
              name="quantity"
            >
              <option value="" selected disabled>
                --- Select Quantity ---
              </option>

              <option value="Instagram Premium 100 Followers">Instagram Premium 100 Followers</option>
              <option value="Instagram Premium 200 Followers">Instagram Premium 200 Followers</option>
              <option value="Instagram Premium 500 Followers">Instagram Premium 500 Followers</option>
              <option value="Instagram Premium 1000 Followers">Instagram Premium 1000 Followers</option>
            </select>
          </div>



          {price ? <h3>Total Price : Rs. {price}/-</h3> : ""}

          {error && <h3 style={{ color: 'red' }}>{error}</h3>}


          <button>
            <i class="fa-solid fa-bolt-lightning"></i> BOOK NOW
          </button>
        </form>
        {/* <button onClick={(e) => setPaymentForm(true)}>form</button> */}

      </div>

      

    </div>
  );
};

export default InstagramPremiumFollowersFormComponent;
