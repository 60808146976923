import React from "react";
import icon1 from "../media/images/Achievements/statistics-1.webp";
import icon2 from "../media/images/Achievements/statistics-2.webp";
import icon3 from "../media/images/Achievements/statistics-3.webp";
import icon4 from "../media/images/Achievements/statistics-4.webp";

const OurAchievementsComponent = () => {
  return (
      <div className="our-achievements">
          <div>
        <div className="our-achievements-heading">Our Achievements</div>
        <div className="our-achievements-content">

          <div className="our-achievements-content-col">
            <div className="our-achievements-content-col-icon"><img src={icon4} alt="icon" title="icon" /></div>
            <div className="our-achievements-content-col-heading">5 Years</div>
            <div className="our-achievements-content-col-text">In Business</div>
          </div>

          <div className="our-achievements-content-col">
            <div className="our-achievements-content-col-icon"><img src={icon1} alt="icon" title="icon" /></div>
            <div className="our-achievements-content-col-heading">50,000</div>
            <div className="our-achievements-content-col-text">Happy Customers</div>
          </div>

          <div className="our-achievements-content-col">
            <div className="our-achievements-content-col-icon"><img src={icon2} alt="icon" title="icon" /></div>
            <div className="our-achievements-content-col-heading">1,00,000</div>
            <div className="our-achievements-content-col-text">Orders Delivered</div>
          </div>

          <div className="our-achievements-content-col">
            <div className="our-achievements-content-col-icon"><img src={icon3} alt="icon" title="icon" /></div>
            <div className="our-achievements-content-col-heading">25</div>
            <div className="our-achievements-content-col-text">Cities Served</div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default OurAchievementsComponent;
