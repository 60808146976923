import React, { useState } from "react";

import play from "../media/images/spotify-plays.jpg";
import followers from "../media/images/spotify-followers.jpg";


import icon from "../media/images/icons/spotify.png";
import SpotifyFollowersFormComponent from "./form/SpotifyFollowersFormComponent";
import SpotifyPlayFormComponent from "./form/SpotifyPlayFormComponent";


const SpotifyFormComponent = () => {

    
    const [type, setType] = useState("Global Plays")
  const [ShowCategory, setShowCategory] = useState(false);
 


  
  return (
    <div>
      
      <div className="header">
        <div className="header-col-2">
          <div className="header-col-2-category" onClick={(e) => setShowCategory(!ShowCategory)}>
          <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={icon} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    Spotify&nbsp;
                    <span>{type}&nbsp;</span>
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
          </div>

          {ShowCategory ? (
            <div className="store-form-category-show-option">

              
              
<div
                onClick={(e) => {
                  setType("Global Plays");
                  setShowCategory(false);
                }}
              >
                Spotify Global Plays
              </div>
              
              <div
                onClick={(e) => {
                  setType("Premium Followers");
                  setShowCategory(false);
                }}
              >
                Spotify Premium Followers
              </div>
              
              
              
            </div>
          ) : (
            ""
          )}
          <div className="header-col-2-heading"><>Spotify <span>{type} </span></>    
          </div>

          <div>


          {
                type === "Premium Followers"?
<SpotifyFollowersFormComponent />
:
                type === "Global Plays"?
                <SpotifyPlayFormComponent/>
:""}


            
          </div>
        </div>

        <div className="header-col-1">

            {
                type === "Premium Followers"?
                <img src={followers} alt="logo" title="logo" />
                :type === "Global Plays"?
                <img src={play} alt="logo" title="logo" />
                :""
            }

        </div>
      </div>
    </div>
  );
};

export default SpotifyFormComponent;
 