import React from "react";
import icon1 from "../media/images/Features/icon-1.webp"
import icon2 from "../media/images/Features/icon-2.webp"
import icon3 from "../media/images/Features/icon-3.webp"
import icon4 from "../media/images/Features/icon-4.webp"
import icon5 from "../media/images/Features/icon-5.webp"
import icon6 from "../media/images/Features/icon-6.webp"

const OurFeaturesComponent = () => {
  return (
    <div>
      <div className="our-features" id="features">
        <div className="our-features-heading">Our Features</div>
        <div className="our-features-content">
            
          <div className="our-features-content-col">
            <div className="our-features-content-col-icon"><img src={icon1} alt="icon" title="icon" /></div>
            <div className="our-features-content-col-text">Experience and Expertise</div>
          </div>
            
          <div className="our-features-content-col">
            <div className="our-features-content-col-icon"><img src={icon2} alt="icon" title="icon" /></div>
            <div className="our-features-content-col-text">The Personal Touch</div>
          </div>
            
          <div className="our-features-content-col">
            <div className="our-features-content-col-icon"><img src={icon3} alt="icon" title="icon" /></div>
            <div className="our-features-content-col-text">Targeted Services</div>
          </div>
            
          <div className="our-features-content-col">
            <div className="our-features-content-col-icon"><img src={icon4} alt="icon" title="icon" /></div>
            <div className="our-features-content-col-text">Privacy & Safety</div>
          </div>
            
          <div className="our-features-content-col">
            <div className="our-features-content-col-icon"><img src={icon5} alt="icon" title="icon" /></div>
            <div className="our-features-content-col-text">Money-Back Guarantee</div>
          </div>
            
          <div className="our-features-content-col">
            <div className="our-features-content-col-icon"><img src={icon6} alt="icon" title="icon" /></div>
            <div className="our-features-content-col-text">Great Customer Support</div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default OurFeaturesComponent;
