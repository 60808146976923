import React from 'react'
import NavbarComponent from '../component/NavbarComponent'
import HomeHeaderComponent from '../component/HomeHeaderComponent'
import FooterComponent from '../component/FooterComponent'
import OurFeaturesComponent from '../component/OurFeaturesComponent'
import OurAchievementsComponent from '../component/OurAchievementsComponent'
import HowOurServiceWorkComponent from '../component/HowOurServiceWorkComponent'
import BenefitComponent from '../component/BenefitComponent'
// import HowToBuyComponent from '../component/HowToBuyComponent'
import WhyUs from '../component/WhyUs'
// import FaqsComponent from '../component/FaqsComponent'
import TeamMemberComponent from '../component/TeamMemberComponent'
// import FooterComponent1 from '../component/FooterComponent1'

const HomePage = () => {
  return (
    <div>
      <NavbarComponent/>
      <HomeHeaderComponent/>


      <OurFeaturesComponent />
      <OurAchievementsComponent />
      <HowOurServiceWorkComponent />
      <BenefitComponent />
      {/* <HowToBuyComponent /> */}

      <TeamMemberComponent/>

      <WhyUs />
      {/* <FaqsComponent /> */}


      <FooterComponent/>
      {/* <FooterComponent1/> */}
      
    </div>
  )
}

export default HomePage