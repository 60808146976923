import React, { useState } from "react";

import img from "../media/images/amazon.png";


import icon from "../media/images/icons/amazon.png";

const AmazonFormComponent = () => {
;
    
    const [type, setType] = useState("Product Rating")
  const [ShowCategory, setShowCategory] = useState(false);




  
  const [user, setUser] = useState({
    name: "",
    number: "",
    state: "", 
    lines: "___Auto Comment___",
    quantity: "",
    url: "",
  });


  const handleChange = (e) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;

    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };
  
  
  return (
    <div>
      
      <div className="header">
        <div className="header-col-2">
          <div className="header-col-2-category" onClick={(e) => setShowCategory(!ShowCategory)}>
          <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={icon} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                  Amazon 5 Stars&nbsp;
                    <span>{type}&nbsp;</span>
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
          </div>

          {ShowCategory ? (

            <div className="store-form-category-show-option">
              
              <div
                onClick={(e) => {
                  setType("Product Rating");
                  setShowCategory(false);
                }}
              >
                Amazon 5 Stars Product Rating
              </div>
              
              
            </div>

          ) : (
            ""
          )}
          <div className="header-col-2-heading"><>Buy Amazon 5 Stars <span>{type} </span></>    
          </div>

          <div>
{/* 
{
    category === "auto"
 ?   <GoogleAutoReviewComponent/>
 :   category === "custom"
 ?
 <GoogleCustomReviewComponent/>
 :""
} */}


<div className="header-col-2-form">


<form >
        <div className="header-col-2-form-col">
          <label>Full Name *</label>
          <input
            onChange={handleChange}
            name="name"
            required
            type="text"
            placeholder="Enter your name"
          />
        </div>

        <div className="header-col-2-form-col">
          <label>Mobile Number *</label>
          <input
            onChange={handleChange}
            name="number"
            required
            type="text"
            placeholder="Enter your phone number"
          />
        </div>

        
        

        <div className="header-col-2-form-col">
          <label>Enter Amazon URL *</label>
          <input
            onChange={handleChange}
            name="url"
            required
            type="text"
            placeholder="https://"
          />
        </div>

        <div className="header-col-2-form-col">
          <label>Select Quantity * </label>
          <select required onChange={handleChange} name="quantity">
            <option value="" selected disabled>
              --- Select Quantity ---
            </option>

            <option value={`100 Amazon 5 Stars ${type}`}>100 Amazon 5 Stars {type}</option>
            <option value={`200 Amazon 5 Stars ${type}`}>200 Amazon 5 Stars {type}</option>
            <option value={`500 Amazon 5 Stars ${type}`}>500 Amazon 5 Stars {type}</option>
            <option value={`1000 Amazon 5 Stars ${type}`}>1,000 Amazon 5 Stars {type}</option>
            <option value={`5000 Amazon 5 Stars ${type}`}>5,000 Amazon 5 Stars {type}</option>
            <option value={`10000 Amazon 5 Stars ${type}`}>10,000 Amazon 5 Stars {type}</option>
           
          </select>
        </div>

        

        {/* {user.quantity === "100 Amazon 5 Stars Followers" ? (
          <h2>Price : 100</h2>
        ) : user.quantity === "200 Amazon 5 Stars Followers" ? (
          <h2>Price : 200</h2>
        ) : user.quantity === "500 Amazon 5 Stars Followers" ? (
          <h2>Price : 500</h2>
        ) : user.quantity === "1000 Amazon 5 Stars Followers" ? (
          <h2>Price : 1000</h2>
        ) : user.quantity === "5000 Amazon 5 Stars Followers" ? (
          <h2>Price : 5000</h2>
        ) : user.quantity === "10000 Amazon 5 Stars Followers" ? (
          <h2>Price : 10000</h2>
        ) : (
          ""
        )} */}


        {user.quantity === `100 Amazon 5 Stars ${type}` ? (
          <h2>Price : 100</h2>
        ) : user.quantity === `200 Amazon 5 Stars ${type}` ? (
          <h2>Price : 200</h2>
        ) : user.quantity === `500 Amazon 5 Stars ${type}` ? (
          <h2>Price : 500</h2>
        ) : user.quantity === `1000 Amazon 5 Stars ${type}` ? (
          <h2>Price : 1000</h2>
        ) : user.quantity === `5000 Amazon 5 Stars ${type}` ? (
          <h2>Price : 5000</h2>
        ) : user.quantity === `10000 Amazon 5 Stars ${type}` ? (
          <h2>Price : 10000</h2>
        ) : (
          ""
        )}

        <button>
          <i class="fa-solid fa-bolt-lightning"></i> BOOK NOW
        </button>
      </form>
</div>



            
          </div>
        </div>

        <div className="header-col-1">
            <img src={img} alt="logo" title="logo" />
            
        </div>
      </div>
    </div>
  );
};

export default AmazonFormComponent;
 