import React from "react";
// import img from "../media/images/home-banner.png";
import img1 from "../media/images/banner-icon.svg";
import { Link } from "react-router-dom";

const HomeHeaderComponent = () => {
  return (
    <div>
      <div className="home-header">
        <div>
          <div className="home-header-col-1">
            <div className="home-header-col-1-heading-1">
              <span>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>

                <i class="fa-solid fa-star-half-stroke"></i>
              </span>
              &nbsp; Excellent 4.47 out of 5 <i class="fa-solid fa-star"></i> REVIEWS
            </div>
            <div className="home-header-col-1-heading-2">
              Feed Backer Can Help You
            </div>
            <h1 className="home-header-col-1-heading-3">
              Grow Your Social Presence
            </h1>
            <div className="home-header-col-1-text">
              We offer a wide range of promotional services to boost your
              presence on all social media platforms.
            </div>
            <Link to="/store" className="home-header-col-1-button">GET STARTED</Link>
            <div className="home-header-col-1-heading-4">
              Top Selling Services
            </div>
            <div className="home-header-col-1-icons">
              <div className="home-header-col-1-google-icon">
                <i class="fa-brands fa-google"></i>
              </div>
              <div className="home-header-col-1-yt-icon">
                <i class="fa-brands fa-youtube"></i>
              </div>
              <div className="home-header-col-1-ig-icon">
                <i class="fa-brands fa-instagram"></i>
              </div>
              <div className="home-header-col-1-fb-icon">
                <i class="fa-brands fa-facebook-f"></i>
              </div>
              <div className="home-header-col-1-amazon-icon">
                <i class="fa-brands fa-amazon"></i>
              </div>
              {/* <Link to="" className="home-header-col-1-ps-icon">
                <i class="fa-brands fa-google-play"></i>
              </Link> */}
            </div>
          </div>
          <div className="home-header-col-2">
            <img src={img1} alt="home banner" title="home banner" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeaderComponent;
