import React from "react";

const LoadingComponent = () => {
  return (
    <div>
      <div className="loading-component">
        <span>Loading</span>
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default LoadingComponent;
