
import './App.css';
import './css/NavbarComponent.css';
import './css/GoogleHeaderComponent.css';
import './css/OurFeaturesComponent.css';
import './css/OurAchievementsComponent.css';
import './css/HowOurServiceWorkComponent.css';
import './css/HowToBuyComponent.css';
import './css/FaqsComponent.css';
import './css/BenefitComponent.css';
import './css/FooterComponent.css';
// import './css/FooterComponent1.css';
import './css/ContactButtons.css';
import './css/HomeHeaderComponent.css';
import './css/StoreFormComponent.css';
import './css/TeamMemberComponent.css';
import './css/PaymentForm.css';

import './css/others/ErrorPage.css';
import './css/others/Loading.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import ContactButtons from './component/ContactButtons';
// import GoogleReviewsPage from './pages/GoogleReviewsPage';
import ScrollToTop from './component/others/ScrollToTop';
import ConfirmBookingPage from './pages/ConfirmBookingPage';
import ErrorPage from './pages/ErrorPage';
import StorePage from './pages/StorePage';
// import OtherPage from './pages/OtherPage';

function App() {
  return (
    <div className="App">
     
     <BrowserRouter>
     
     <ScrollToTop/>
        <Routes>
          <Route path='/' element={<HomePage />}></Route>
          {/* <Route path='/buy-google-reviews' element={<GoogleRevie wsPage />}></Route> */}
          <Route path='/store' element={<StorePage />}></Route>

          {/* <Route path='/other' element={<OtherPage />}></Route> */}

          <Route path='/thanks' element={<ConfirmBookingPage />}></Route>
          <Route path='/*' element={<ErrorPage />}></Route>
          
        </Routes>
        <ContactButtons/>
      </BrowserRouter>
     
    </div>
  );
}

export default App;
