import React from "react";
import img from "../media/images/how-our-services-work.svg";

const HowOurServiceWorkComponent = () => {
  return (
    <div>
      <div className="service-work">
        <div className="service-work-heading">How Our Services Work?</div>
        <div className="service-work-content">
          <div className="service-work-content-col-1">
            <img src={img} alt="" />
          </div>
          <div className="service-work-content-col">

            <div className="service-work-content-col-point">
              <div className="service-work-content-col-point-num">01</div>
              <div className="service-work-content-col-point-content">
                <div className="service-work-content-col-point-heading">Select the package that is right for you</div>
                <div className="service-work-content-col-point-txt">Choose from dozens of popular social networks using the links at the top of the page.</div>
              </div>
            </div>

            <div className="service-work-content-col-point service-work-content-col-point-2">
              <div className="service-work-content-col-point-num">02</div>
              <div className="service-work-content-col-point-content">
                <div className="service-work-content-col-point-heading">Provide necessary information</div>
                <div className="service-work-content-col-point-txt">Enter the URL of your content or your social media username when prompted.</div>
              </div>
            </div>

            <div className="service-work-content-col-point service-work-content-col-point-3">
              <div className="service-work-content-col-point-num service-work-content-col-point-num-3">03</div>
              <div className="service-work-content-col-point-content">
                <div className="service-work-content-col-point-heading">Checkout securely</div>
                <div className="service-work-content-col-point-txt">Complete the secure online payment process, and we'll begin delivering your order.</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default HowOurServiceWorkComponent;
