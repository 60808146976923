import React from 'react'
import { Link } from 'react-router-dom'
import img from "../media/images/jagdish.jpeg"

const TeamMemberComponent = () => {
  return (
    <div>
        <div className="team-member-component">
    <div>
        <div className="team-member-component-heading"> <span>Our</span> Social Media Expert</div>
        <div className="team-member-component-content">
        <div className="team-member-component-content-img"><img src={img} alt="" /></div>
        <div className="team-member-component-content-col">
            <div>

        <div className="team-member-component-content-heading-1">Jagdish Rajput</div>
        <div className="team-member-component-content-heading-2">Social Media Expert</div>
        <div className="team-member-component-content-text">
        {/* Co-Founder at We Excel Learning Center | Freelance English and French Language Coach. My journey in education began in 2009 when I started as a team-member with GD Goenka School, Ryan International and Venkateshwar Global School.

Over the years, I have discovered my passion for guiding students achieve their dreams through mastering English language tests like the IELTS and PTE. 

Therefore, We Excel Learning Center is my one step forward to achieving that dream to guide learners through the process of learning with utmost joy and fun !
 */}

{/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae debitis consequatur animi hic et cumque omnis impedit placeat necessitatibus dolorem, rem ea odit nihil enim quis quae voluptate vel alias modi. Repellendus distinctio ea accusamus nulla illum excepturi reprehenderit non cumque, cum deserunt. Incidunt tenetur fugit numquam dignissimos sunt mollitia quasi quod nostrum vitae obcaecati consectetur dolorum, sed neque? Iusto ipsum velit molestias quod quibusdam autem, quia aut praesentium quam dicta necessitatibus harum quisquam eveniet ducimus consectetur? Molestias, perspiciatis ipsum? */}
{/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia recusandae dolorem laborum odio adipisci esse earum magni architecto molestias blanditiis ratione neque iure dolor maxime, facilis impedit rerum ea perferendis velit cum consectetur corporis. Quod eaque rem odio atque vel? Quas consectetur consequatur omnis esse qui ab quos, tempore culpa! */}
Jagdish Kumar Singh, also known as Jagdish Rajput, is a distinguished social media expert with an exceptional track record of managing accounts for numerous companies across popular platforms like Instagram, YouTube, Facebook, and Google Reviews. His expertise in driving engagement through likes, comments, reach, and follower growth on Instagram is unparalleled. Jagdish Kumar Singh has an innate ability to understand and leverage the nuances of social media dynamics, making him a master at crafting strategies that yield impressive results.
        </div>
        <div className="team-member-component-content-button"><Link to="/store">Explore Now</Link></div>

        </div>

            </div>
        </div>

        </div>
    </div>
    </div>
  )
}

export default TeamMemberComponent