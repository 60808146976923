import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "./others/LoadingComponent";

import qr from "../media/images/qr.jpeg";

const PaymentFormComponent = ({close, heading, name, number, country, url, quantity, price}) => {

  const [loading, setLoading] = useState(false);

    
  const [user, setUser] = useState({

    formHeading : heading, 
    name : name, 
    number : number, 
    country : country, 
    url : url, 
    quantity : quantity, 
    price : price, 
    transactionID : "", 
    date : "", 
    time : ""
    
  });

  const handleChange = (e) => {
    const value = e.target.value;

    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      // const response = await fetch("http://localhost:5004/payment-form", {
      const response = await fetch("https://api.review.bhumu.com/payment-form",{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );

      if (response.ok) {
        alert("Service booked successfully!");
        navigate("/thanks");
      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }
    } catch (error) {
      alert("server error");
    }
    setLoading(false);
  };

  
  return (
      <div>
        {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
    <div className="payment-form">
      <div>
        <div className="payment-form-close">
          <i class="fa-solid fa-circle-xmark" onClick={e=>close(false)}></i>
        </div>
        <div className="payment-form-content">
        <div className="payment-form-content-col-1">
          <div className="payment-form-content-img">
            <img src={qr} alt="payment code" />
          </div></div>
        <div className="payment-form-content-col-2">
          <h2>{quantity}</h2>
          <div className="payment-form-content-heading">
            Pay via QR Code and enter Order ID (from your app) in field below
          </div>
          <div className="payment-form-content-form">
            <form onSubmit={handleSubmit}>
              <div className="payment-form-content-form-input">
                <label>Payment amount</label>
                <input
                  type="text"
                  placeholder="total payment"
                  value={`Rs. ${price}/-`}
                  disabled
                />
              </div>



              <div className="payment-form-content-form-input">
                <label>Payment Transaction ID </label>
                <input required name="transactionID" onChange={handleChange} type="text" placeholder="Enter Transaction Id" />
              </div>


              <div className="payment-form-content-form-input">
                <label>Payment Date & Time </label>
                <div>
                <input required name="date" onChange={handleChange} type="text" placeholder="enter payment date" />
                <input required name="time" onChange={handleChange} type="text" placeholder="enter payment time" />

                </div>
              </div>

              
              <button>Confirm</button>
            </form>
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default PaymentFormComponent;
