import React, { useState } from "react";

import ytViews from "../media/images/Youtube-review.png";
import ytLike from "../media/images/Youtube-like.png";
import ytComment from "../media/images/Youtube-comment.png";
import ytSubscriber from "../media/images/Youtube-subscribe.png";

import icon from "../media/images/icons/youtube.png";
import YoutubePremiumSubscribersFormComponent from "./form/YoutubePremiumSubscribersFormComponent";
import YoutubePremiumViewsFormComponent from "./form/YoutubePremiumViewsFormComponent";
import YoutubePremiumLikesFormComponent from "./form/YoutubePremiumLikesFormComponent";
import YoutubePremiumCommentsFormComponent from "./form/YoutubePremiumCommentsFormComponent";


const YoutubeFormComponent = () => {
    
    const [type, setType] = useState("Subscribers")
  const [ShowCategory, setShowCategory] = useState(false);
 



  

  
  
  return (
    <div>
      
      <div className="header">
        <div className="header-col-2">
          <div className="header-col-2-category" onClick={(e) => setShowCategory(!ShowCategory)}>
          <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={icon} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    Youtube Premium&nbsp;
                    <span>{type}&nbsp;</span>
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
          </div>

          {ShowCategory ? (
            <div className="store-form-category-show-option">
              
              <div
                onClick={(e) => {
                  setType("Subscribers");
                  setShowCategory(false);
                }}
              >
                Youtube Premium Subscribers
              </div>
              
              <div
                onClick={(e) => {
                  setType("Views");
                  setShowCategory(false);
                }}
              >
                Youtube Premium Views
              </div>
              
              <div
                onClick={(e) => {
                  setType("Likes");
                  setShowCategory(false);
                }}
              >
                Youtube Premium Likes
              </div>
              
              <div
                onClick={(e) => {
                  setType("Comments");
                  setShowCategory(false);
                }}
              >
                Youtube Premium Comments
              </div>
              
              
            </div>
          ) : (
            ""
          )}
          <div className="header-col-2-heading"><>Buy Youtube Premium <span>{type} </span></>    
          </div>

          <div>


          {
                type === "Subscribers"?
<YoutubePremiumSubscribersFormComponent/>
:
                type === "Views"?
                <YoutubePremiumViewsFormComponent/>
:
                type === "Likes"?
                <YoutubePremiumLikesFormComponent/>
:
                type === "Comments"?
                <YoutubePremiumCommentsFormComponent/>
:""}


            
          </div>
        </div>

        <div className="header-col-1">

            {
                type === "Subscribers"?
                <img src={ytSubscriber} alt="logo" title="logo" />
                :type === "Likes"?
                <img src={ytLike} alt="logo" title="logo" />
                :type === "Views"?
                <img src={ytViews} alt="logo" title="logo" />
                :type === "Comments"?
                <img src={ytComment} alt="logo" title="logo" />
                :""
            }

        </div>
      </div>
    </div>
  );
};

export default YoutubeFormComponent;
 