import React, { useState } from "react";

import x from "../media/images/x.jpg";


import icon from "../media/images/icons/X.png";
import TwitterFollowersFormComponent from "./form/TwitterFollowersFormComponent";


const TwitterFormComponent = () => {

    
    const [type, setType] = useState("Followers")
  const [ShowCategory, setShowCategory] = useState(false);
 


  
  return (
    <div>
      
      <div className="header">
        <div className="header-col-2">
          <div className="header-col-2-category" onClick={(e) => setShowCategory(!ShowCategory)}>
          <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={icon} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    X / Twitter Premium &nbsp;
                    <span>{type}&nbsp;</span>
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
          </div>

          {ShowCategory ? (
            <div className="store-form-category-show-option">

                            
              <div
                onClick={(e) => {
                  setType("Followers");
                  setShowCategory(false);
                }}
              >
                X / Twitter Premium Followers
              </div>
              
              
              
            </div>
          ) : (
            ""
          )}
          <div className="header-col-2-heading"><>X / Twitter Premium <span>{type} </span></>    
          </div>

          <div>


          {
                type === "Followers"?
<TwitterFollowersFormComponent />

:""}


            
          </div>
        </div>

        <div className="header-col-1">

            {
                type === "Followers"?
                <img src={x} alt="logo" title="logo" />
                
                :""
            }

        </div>
      </div>
    </div>
  );
};

export default TwitterFormComponent;
 