import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import data from "./country.json";


import LoadingComponent from "../others/LoadingComponent";
import PaymentFormComponent from "../PaymentFormComponent";

const GoogleAutoReviewComponent = () => {
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  const [price, setPrice] = useState("");
  const [paymentForm, setPaymentForm] = useState(false);

  
  const handleclick = (e) => {
    if (user.quantity === "5 Google Map Reviews") {
      setPrice("499");
    }
    if (user.quantity === "10 Google Map Reviews") {
      setPrice("899");
    }
    if (user.quantity === "25 Google Map Reviews") {
      setPrice("1,999");
    }
    if (user.quantity === "50 Google Map Reviews") {
      setPrice("3,799");
    }
    if (user.quantity === "100 Google Map Reviews") {
      setPrice("6,999");
    }
    if (user.quantity === "500 Google Map Reviews") {
      setPrice("29,999");
    }
    if (user.quantity === "1000 Google Map Reviews") {
      setPrice("49,999");
    }
  };

  
  const [user, setUser] = useState({
    formHeading: "Google Map Auto Review",
    name: "",
    number: "",
    country: "",
    url: "",
    quantity: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setError('');

    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  // const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

     
    if (! user.url.startsWith("https://maps.app.goo.gl/")) {
      setError('please enter valid url');
      setLoading(false);
      return; 
    }
    
    
    try {
      // const response = await fetch("http://localhost:5004/booking-form", {
      const response = await fetch("https://api.review.bhumu.com/booking-form",{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );

      if (response.ok) {
        alert("Service booked successfully! Please confirm your payment");
        // navigate("/booking-confirm");
        setPaymentForm(true)
      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }
    } catch (error) {
      alert("server error");
    }
    setLoading(false);
  };


  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

      {paymentForm ? (
        <PaymentFormComponent
          close={setPaymentForm}
          heading={user.formHeading}
          name={user.name}
          number={user.number}
          country={user.country}
          url={user.url}
          quantity={user.quantity}
          price={price}
        />
      ) : (
        ""
      )}

      <div className="header-col-2-form">
        <form onSubmit={handleSubmit}>
          <div className="header-col-2-form-col">
            <label>Full Name *</label>
            <input
              onChange={handleChange}
              name="name"
              required
              type="text"
              placeholder="Enter your name"
            />
          </div>

          
          <div className="header-col-2-form-col">
            <label>Select Target Country *</label>
            <select onChange={handleChange} name="country" required>
              <option value="" selected disabled>
                --- Select Target Country ---
              </option>
              {data.map((e) => (
                <option value={`${e.name} (${e.dial_code})`}>{e.name}</option>
              ))}
            </select>
          </div>

          {user.country && 
          <div className="header-col-2-form-col">
            <label>Mobile Number *</label>
            <div>

              <span>
                {data
                  .filter((e) => (`${e.name} (${e.dial_code})`) === user.country)
                  .map((e) => (
                    <div>
                      {e.code} ({e.dial_code})
                    </div>
                  ))}
              </span>

              <input
                onChange={handleChange}
                name="number"
                min="0"
                required
                type="number"
                placeholder="Enter your phone number"
              />
            </div>
          </div>
}

          <div className="header-col-2-form-col">
            <label>Enter Google My Business/Google Maps URL *</label>
            <input
              onChange={handleChange}
              name="url"
              required
              type="text"
              placeholder="https://maps.app.goo.gl/"
            />
          </div>

          <div className="header-col-2-form-col">
            <label>Select Quantity * </label>
            <select
              required
              onClick={handleclick}
              onChange={handleChange}
              name="quantity"
            >
              <option value="" selected disabled>
                --- Select Quantity ---
              </option>

              <option value="5 Google Map Reviews">5 Google Map Reviews</option>
              <option value="10 Google Map Reviews">10 Google Map Reviews</option>
              <option value="25 Google Map Reviews">25 Google Map Reviews</option>
              <option value="50 Google Map Reviews">50 Google Map Reviews</option>
              <option value="100 Google Map Reviews">100 Google Map Reviews</option>
              <option value="500 Google Map Reviews">500 Google Map Reviews</option>
              <option value="1000 Google Map Reviews">1000 Google Map Reviews</option>
            </select>
          </div>



          {price ? <h3>Total Price : Rs. {price}/-</h3> : ""}

          {error && <h3 style={{ color: 'red' }}>{error}</h3>}


          <button>
            <i class="fa-solid fa-bolt-lightning"></i> BOOK NOW
          </button>
        </form>
        {/* <button onClick={(e) => setPaymentForm(true)}>form</button> */}
      </div>

      {/* <div className="header-col-2-form-col">
            <label>Select Target State *</label>
            <select onChange={handleChange} name="state" required>
              <option value="" selected disabled>
                --- Select Target State ---
              </option>
              <option value="Andaman and Nicobar Islands">
                Andaman and Nicobar Islands
              </option>
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Dadra and Nagar Haveli and Daman and Diu">
                Dadra and Nagar Haveli and Daman and Diu
              </option>
              <option value="Delhi">Delhi</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jammu and Kashmir">Jammu and Kashmir</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Ladakh">Ladakh</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Puducherry">Puducherry</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="Uttarakhand ">Uttarakhand </option>
            </select>
          </div> */}

    </div>
  );
};

export default GoogleAutoReviewComponent;
