import React from 'react'

const WhyUs = () => {
  return (
    <div>
         <div>
         <div className="benefit" id='why-us'>
          <div>
        {/* <div className="benefit-heading">We Served <span>Landing Pages</span>  To These <span>Industries</span> </div> */}
        <div className="benefit-heading">Why Choose Us?<span></span> </div>
        <div className="benefit-content">
        <div className="benefit-content-points-col">
        <div className="benefit-content-point"><span>■</span> Proven Expertise</div>
        <div className="benefit-content-point"><span>■</span> Quality Assurance</div>
        <div className="benefit-content-point"><span>■</span> Customer-Centric Approach</div>
        <div className="benefit-content-point"><span>■</span> Innovative Solutions</div>
        <div className="benefit-content-point"><span>■</span> Transparent Communication</div>
        <div className="benefit-content-point"><span>■</span> Competitive Pricing</div>
        <div className="benefit-content-point"><span>■</span> Experienced Team</div>
        <div className="benefit-content-point"><span>■</span> Timely Delivery</div>
        <div className="benefit-content-point"><span>■</span> Comprehensive Support</div>
        <div className="benefit-content-point"><span>■</span> Positive Reputation</div>
        <div className="benefit-content-point"><span>■</span> Ethical Practices</div>
        <div className="benefit-content-point"><span>■</span> Long-Term Partnerships</div>
        
        </div>
        

        </div>

        </div>
    </div>
    </div>
    </div>
  )
}

export default WhyUs