import React, { useState } from 'react'
import data from "./country.json";


import LoadingComponent from '../others/LoadingComponent';
import PaymentFormComponent from "../PaymentFormComponent";


const GoogleCustomReviewComponent = () => {
  const [error, setError] = useState('');

  const [qnt, setQnt] = useState(false)
  
  const [paymentForm, setPaymentForm] = useState(false);
  
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    
    formHeading: "Google Map Custome Review",
    name: "",
    number: "",
    country: "",
    lines: "",
    quantity: "",
    url: "",
  });

  const text = user.lines;
  const totalLines = text.split(`\n\n`).filter((e) => {
    return e.length  !== 0;
  }).length;
  

  const handleChange = (e) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;

    const value = e.target.value;

    setError('');
    setQnt(true)
    
    setUser({
      ...user,
      quantity : totalLines,
      [e.target.name]: value,
    });
  };

 
  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

     
    if (! user.url.startsWith("https://maps.app.goo.gl/")) {
      setError('please enter valid url');
      setLoading(false);
      return; 
    }
    
    
    try {
      // const response = await fetch("http://localhost:5004/booking-form", {
      const response = await fetch("https://api.review.bhumu.com/booking-form",{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );

      if (response.ok) {
        alert("Service booked successfully! Please confirm your payment");
        // navigate("/booking-confirm");
        setPaymentForm(true)
      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }
    } catch (error) {
      alert("server error");
    }
    setLoading(false);
  };



  // console.log("lines ", totalLines);

  
  
  return (
    <div>


{loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
      
      {paymentForm ? (
        <PaymentFormComponent
          close={setPaymentForm}
          heading={user.formHeading}
          name={user.name}
          number={user.number}
          country={user.country}
          url={user.url}
          quantity={totalLines}
          price={totalLines*100}
        />
      ) : (
        ""
      )}

      

<div className="header-col-2-form">

      
       <form onSubmit={handleSubmit}>
              <div className="header-col-2-form-col">
                <label>Full Name *</label>
                <input
                  onChange={handleChange}
                  name="name"
                  required
                  type="text"
                  placeholder="Enter your name"
                />
              </div>

              
              
          
          <div className="header-col-2-form-col">
            <label>Select Target Country *</label>
            <select onChange={handleChange} name="country" required>
              <option value="" selected disabled>
                --- Select Target Country ---
              </option>
              {data.map((e) => (
                <option value={`${e.name} (${e.dial_code})`}>{e.name}</option>
              ))}
            </select>
          </div>

          {user.country && 
          <div className="header-col-2-form-col">
            <label>Mobile Number *</label>
            <div>

              <span>
                {data
                  .filter((e) => (`${e.name} (${e.dial_code})`) === user.country)
                  .map((e) => (
                    <div>
                      {e.code} ({e.dial_code})
                    </div>
                  ))}
              </span>

              <input
                onChange={handleChange}
                name="number"
                min="0"
                required
                type="number"
                placeholder="Enter your phone number"
              />
            </div>
          </div>
}

          <div className="header-col-2-form-col">
            <label>Enter Google My Business/Google Maps URL *</label>
            <input
              onChange={handleChange}
              name="url"
              required
              type="text"
              placeholder="https://maps.app.goo.gl/"
            />
          </div>



              <div className="header-col-2-form-col">
                <label>Enter Custom Reviews (add double enter for next comment) </label>
                <textarea required              
                  onChange={handleChange}
                  name="lines"
                  type="text"
                  placeholder="Enter one review per line."
                />
              </div>


<span onClick={e=>setQnt(false)}>
  {
    qnt?
    <>
{/* <input required onChange={handleChange} type="checkbox" checked={false} name="off" id="off" /> comments are  */}
<input required onChange={handleChange} type="checkbox" /> comments are correct
    </>
:<>
<input required onChange={handleChange} type="checkbox" /> comments are correct?
</>
  }
  </span>


<input type="checkbox" checked={0} />
  

              {totalLines>0 && <h3><div>Quantity : {totalLines}</div> <div>Price : {totalLines*100}/-</div></h3>}

              
              {/* <h3><div>Quantity : <input type="text" onChange={handleChange} name="quantity" value={totalLines} /></div> <div>Price : {totalLines*100}/-</div></h3> */}
              <h3><div>Quantity - {user.quantity}</div> <div>Price : {user.quantity*100}/-</div></h3>
               
              
        {/* <button onClick={(e) => setPaymentForm(true)}>form</button> */}

              <button>
                <i class="fa-solid fa-bolt-lightning"></i> BOOK NOW
              </button>
            </form>
    </div>
    </div>
  )
}

export default GoogleCustomReviewComponent