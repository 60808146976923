import React, { useState } from "react";
import data from './form/country.json'

import img from "../media/images/Playstore.png";


import icon from "../media/images/icons/playstore.png";
import PaymentFormComponent from "./PaymentFormComponent";
import LoadingComponent from "./others/LoadingComponent";

const PlaystoreFormComponent = () => {
  const [error, setError] = useState('');

  
  const [type, setType] = useState("App Review")
  const [ShowCategory, setShowCategory] = useState(false);

  
  
  const [loading, setLoading] = useState(false);

  const [paymentForm, setPaymentForm] = useState(false);

  
  
  const [user, setUser] = useState({
    formHeading: "PlayStore App Review ",
    name: "",
    number: "",
    country: "",
    url: "",
    quantity: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setError('');

    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  // const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

    if (! user.url.startsWith("https://play.google.com/")) {
      setError('please enter valid url !');
      setLoading(false);
      return; 
    }
    
    
    
    try {
      // const response = await fetch("http://localhost:5004/booking-form", {
      const response = await fetch("https://api.review.bhumu.com/booking-form",{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );

      if (response.ok) {
        alert("Service booked successfully! Please confirm your payment");
        // navigate("/booking-confirm");
        setPaymentForm(true)
      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }
    } catch (error) {
      alert("server error");
    }
    setLoading(false);
  };

  
  
  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

      {paymentForm ? (
        <PaymentFormComponent
          close={setPaymentForm}
          heading={user.formHeading}
          name={user.name}
          number={user.number}
          country={user.country}
          url={user.url}
          quantity={` PlayStore - ${user.quantity} App Review`}
          price={user.quantity*150}
        />
      ) : (
        ""
      )}
      <div className="header">
        <div className="header-col-2">
          <div className="header-col-2-category" onClick={(e) => setShowCategory(!ShowCategory)}>
          <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={icon} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                  PlayStore 5 Stars&nbsp;
                    <span>{type}&nbsp;</span>
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
          </div>

          {ShowCategory ? (

            <div className="store-form-category-show-option">
              
              <div
                onClick={(e) => {
                  setType("App Review");
                  setShowCategory(false);
                }}
              >
                PlayStore 5 Stars App Review
              </div>
              
              
            </div>

          ) : (
            ""
          )}
          <div className="header-col-2-heading"><>Buy PlayStore 5 Stars <span>{type} </span></>    
          </div>

          <div>


<div className="header-col-2-form">


<form  onSubmit={handleSubmit}>
        <div className="header-col-2-form-col">
          <label>Full Name *</label>
          <input
            onChange={handleChange}
            name="name"
            required
            type="text"
            placeholder="Enter your name"
          />
        </div>


      
        
        <div className="header-col-2-form-col">
            <label>Select Target Country *</label>
            <select onChange={handleChange} name="country" required>
              <option value="" selected disabled>
                --- Select Target Country ---
              </option>
              {data.map((e) => (
                <option value={`${e.name} (${e.dial_code})`}>{e.name}</option>
              ))}
            </select>
          </div>

          {user.country && 
          <div className="header-col-2-form-col">
            <label>Mobile Number *</label>
            <div>

              <span>
                {data
                  .filter((e) => (`${e.name} (${e.dial_code})`) === user.country)
                  .map((e) => (
                    <div>
                      {e.code} ({e.dial_code})
                    </div>
                  ))}
              </span>

              <input
                onChange={handleChange}
                name="number"
                min="0"
                required
                type="number"
                placeholder="Enter your phone number"
              />
            </div>
          </div>
}



        

        <div className="header-col-2-form-col">
          <label>Enter Playstore URL *</label>
          <input
            onChange={handleChange}
            name="url"
            required
            type="text"
            placeholder="https://play.google.com/"
          />
        </div>

        <div className="header-col-2-form-col">
          <label>Enter Quantity * </label>
          <input min={0} type="number" required onChange={handleChange} name="quantity" />
          </div>

      

{user.quantity ? <h3>Total Price : Rs. {user.quantity * 150}/-</h3> : ""}




{error && <h3 style={{ color: 'red' }}>{error}</h3>}


        <button>
          <i class="fa-solid fa-bolt-lightning"></i> BOOK NOW
        </button>
      </form>
        {/* <button onClick={(e) => setPaymentForm(true)}>form</button> */}

</div>



            
          </div>
        </div>

        <div className="header-col-1">
            <img src={img} alt="logo" title="logo" />
            
        </div>
      </div>
    </div>
  );
};

export default PlaystoreFormComponent;
 