import React from 'react'

const BenefitComponent = () => {
  return (
    <div>
         <div className="benefit" id='benefits'>
          <div>
        {/* <div className="benefit-heading">We Served <span>Landing Pages</span>  To These <span>Industries</span> </div> */}
        {/* <div className="benefit-heading">Benefits of Buying Google Reviews<span></span> </div> */}
        <div className="benefit-heading">Benefits of Buying Services<span></span> </div>
        <div className="benefit-content">
        <div className="benefit-content-points-col">
        <div className="benefit-content-point"><span>■</span> Enhanced Online Reputation</div>
        <div className="benefit-content-point"><span>■</span> Increased Visibility</div>
        <div className="benefit-content-point"><span>■</span> Higher Customer Conversion Rates</div>
        <div className="benefit-content-point"><span>■</span> Competitive Advantage</div>
        <div className="benefit-content-point"><span>■</span> Quick Results</div>
        <div className="benefit-content-point"><span>■</span> Customer Trust</div>
        <div className="benefit-content-point"><span>■</span> Improved Local SEO</div>
        <div className="benefit-content-point"><span>■</span> Better Insights and Feedback</div>
        <div className="benefit-content-point"><span>■</span> Increased Sales and Revenue</div>
        <div className="benefit-content-point"><span>■</span> Enhanced Brand Image</div>
        <div className="benefit-content-point"><span>■</span> Customer Engagement</div>
        <div className="benefit-content-point"><span>■</span> Cost-Effective Marketing</div>
        
        </div>
        

        </div>

        </div>
    </div>
    </div>
  )
}

export default BenefitComponent