import React, { useState } from "react";

import review from "../media/images/facebook-reviw.png";
import comment from "../media/images/facebook-comment.png";
import request from "../media/images/facebook-friend-requests.png";
import group from "../media/images/facebook-group-member.png";
import like from "../media/images/facebook-like.png";
import range from "../media/images/facebook-range.png";
import share from "../media/images/facebook-shares.png";
import view from "../media/images/facebook-view.png";


import icon from "../media/images/icons/facebook.png";
import FBPremiumFollowersFormComponent from "./form/FBPremiumFollowersFormComponent";
import FBPremiumLikesFormComponent from "./form/FBPremiumLikesFormComponent";
import FBPremiumViewsFormComponent from "./form/FBPremiumViewsFormComponent";
import FBPremiumCommentsFormComponent from "./form/FBPremiumCommentsFormComponent";
import FBPremiumPostShareFormComponent from "./form/FBPremiumPostShareFormComponent";
import FBPremiumReachFormComponent from "./form/FBPremiumReachFormComponent";

const FacebookFormComponent = () => {
;
    
    const [type, setType] = useState("Likes")
  const [ShowCategory, setShowCategory] = useState(false);




  
  const [user, setUser] = useState({
    name: "",
    number: "",
    state: "", 
    lines: "___Auto Comment___",
    quantity: "",
    url: "",
  });


  const handleChange = (e) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;

    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };
  
  
  return (
    <div>
      
      <div className="header">
        <div className="header-col-2">
          <div className="header-col-2-category" onClick={(e) => setShowCategory(!ShowCategory)}>
          <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={icon} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    Facebook Premium&nbsp;
                    <span>{type}&nbsp;</span>
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
          </div>

          {ShowCategory ? (

<div className="store-form-category-show-option">
              
<div
  onClick={(e) => {
    setType("Followers");
    setShowCategory(false);
  }}
>
  Instagram Premium Followers
</div>

<div
  onClick={(e) => {
    setType("Views");
    setShowCategory(false);
  }}
>
  Instagram Premium Views
</div>

<div
  onClick={(e) => {
    setType("Likes");
    setShowCategory(false);
  }}
>
  Instagram Premium Likes
</div>

<div
  onClick={(e) => {
    setType("Comments");
    setShowCategory(false);
  }}
>
  Instagram Premium Comments
</div>

<div
  onClick={(e) => {
    setType("Post Share");
    setShowCategory(false);
  }}
>
  Instagram Premium Post Share
</div>

<div
  onClick={(e) => {
    setType("Reach");
    setShowCategory(false);
  }}
>
  Instagram Premium Reach
</div>


</div>

          ) : (
            ""
          )}
          <div className="header-col-2-heading"><>Buy Facebook Premium <span>{type} </span></>    
          </div>

          <div>
{/* 
{
    category === "auto"
 ?   <GoogleAutoReviewComponent/>
 :   category === "custom"
 ?
 <GoogleCustomReviewComponent/>
 :""
} */}



{
                type === "Followers"?
                <FBPremiumFollowersFormComponent/>
              :  type === "Likes"?
                <FBPremiumLikesFormComponent/>
              :  type === "Views"?
                <FBPremiumViewsFormComponent/>
              :  type === "Comments"?
                <FBPremiumCommentsFormComponent/>
              :  type === "Post Share"?
                <FBPremiumPostShareFormComponent />
              :  type === "Reach"?
                <FBPremiumReachFormComponent />
             :""}



            
          </div>
        </div>

        <div className="header-col-1">
            {
                type === "Reviews"?
                <img src={review} alt="logo" title="logo" />
                :type === "Comments"?
                <img src={comment} alt="logo" title="logo" />
                :type === "Friend Requests"?
                <img src={request} alt="logo" title="logo" />
                :type === "Reach"?
                <img src={group} alt="logo" title="logo" />
                :type === "Likes"?
                <img src={like} alt="logo" title="logo" />
                :type === "Followers"?
                <img src={range} alt="logo" title="logo" />
                :type === "Post Share"?
                <img src={share} alt="logo" title="logo" />
                :type === "Views"?
                <img src={view} alt="logo" title="logo" />
                :""
            }
        </div>
      </div>
    </div>
  );
};

export default FacebookFormComponent;
 