import React from 'react'
import StoreFormComponent from '../component/StoreFormComponent'
import NavbarComponent from '../component/NavbarComponent'
import FooterComponent from '../component/FooterComponent'

const StorePage = () => {   
  return (
    <div>
        <NavbarComponent/>
        <StoreFormComponent/>

        {/* <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /> */}
        
        <FooterComponent/>
        
        </div>
  )
}

export default StorePage