// import React, { useRef } from "react";
import React from "react";
import { Link } from "react-router-dom";

const NavbarComponent = () => {
  // const navRef = useRef();

  // const showNavbar = () => {
  //   navRef.current.classList.toggle("responsive_nav");
  // };
  return (
    <div className="navbar">

      {/* <div>
        <Link to="/" className="navbar-logo">Feed Backer</Link>

        <div className="navbar-menu" ref={navRef}>

          <Link to="/buy-google-reviews" className="navbar-menu-point">Google</Link>
          <Link to="" className="navbar-menu-point">YouTube</Link>
          <Link to="" className="navbar-menu-point">Instagram</Link>
          <Link to="" className="navbar-menu-point">Facebook</Link>
          <Link to="" className="navbar-menu-point">Amazon</Link>
          <Link to="" className="navbar-menu-point">Zomato</Link>
          <Link to="" className="navbar-menu-point">PlayStore</Link>
          <Link to="" className="navbar-menu-point">AppStore</Link>

          <Link to='tel:+91-9871-599-433' target='_blank' className="navbar-button">Enquire Now</Link>
          
          <div className="nav-btn nav-close-btn" onClick={showNavbar}>
          <i className="fa-solid fa-circle-xmark"></i>
        </div>
          
        </div>

        <div className="nav-btn" onClick={showNavbar}>
        <i className="fa-solid fa-bars"></i>
      </div>
        
      </div> */}


<div>
<Link to="/" className="navbar-logo">Feed Backer</Link>
{/* <Link to='tel:+91-9871-599-433' target='_blank' className="navbar-button">Enquire Now</Link> */}
<Link to='/store' className="navbar-button">Book Now</Link>


</div>
      
    </div>
  );
};

export default NavbarComponent;
