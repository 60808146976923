import React, { useState } from "react";
import img from "../media/images/google-review.png";
import map from "../media/images/icons/map.png";

import GoogleAutoReviewComponent from "../component/form/GoogleAutoReviewComponent";
import GoogleCustomReviewComponent from "../component/form/GoogleCustomReviewComponent";

const GoogleFormComponent = () => {
  const [category, setCategory] = useState("auto");
  const [ShowCategory, setShowCategory] = useState(false);

  
  return (
    <div>
      
      <div className="header">
        <div className="header-col-2">
          <div
            className="header-col-2-category"
            onClick={(e) => setShowCategory(!ShowCategory)}
          >
            {category === "auto" ? (
              <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={map} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    Google Map 5 Stars&nbsp;
                    <span>Auto&nbsp;</span>
                    Reviews
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
            ) : category === "custom" ? (
              <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={map} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    Google Map 5 Stars&nbsp;
                    <span>
                      Custom
                    </span>
                    &nbsp;Reviews
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          {ShowCategory ? (
            <div className="store-form-category-show-option">
              <div
                onClick={(e) => {
                  setCategory("auto");
                  setShowCategory(false);
                }}
              >
                Google Map 5 Stars Auto Reviews
              </div>
              {/* <div
                onClick={(e) => {
                  setCategory("custom");
                  setShowCategory(false);
                }}
              >
                Google Map 5 Stars Custom Reviews
              </div> */}
            </div>
          ) : (
            ""
          )}
          <div className="header-col-2-heading">
          {
    category === "auto"
 ?  
 <>Buy Google Map <span>Auto </span>Reviews</>

 :   category === "custom"
 ?
 <>Buy Google Map <span>Custom </span>Reviews</>

 :""
}
            
          </div>

          <div>

{
    category === "auto"
 ?   <GoogleAutoReviewComponent/>
 :   category === "custom"
 ?
 <GoogleCustomReviewComponent/>
 :""
}
            
          </div>
        </div>

        <div className="header-col-1">
          <img src={img} alt="logo" title="logo" />
        </div>
      </div>
    </div>
  );
};

export default GoogleFormComponent;
