import React, { useState } from "react";
// import storeIcon from "../media/images/icons/store.png";
import map from "../media/images/icons/map.png";
import appstore from "../media/images/icons/appstore.png";
import playstore from "../media/images/icons/playstore.png";
import zomato from "../media/images/icons/zomato.png";
import amazon from "../media/images/icons/amazon.png";
import facebook from "../media/images/icons/facebook.png";
import instagram from "../media/images/icons/instagram.webp";
import youtube from "../media/images/icons/youtube.png";
import spotify from "../media/images/icons/spotify.png";
import x from "../media/images/icons/X.png";
import linkedin from "../media/images/icons/linkedin.png";

import GoogleFormComponent from "./GoogleFormComponent"; 
import FormCommingSoon from "./FormCommingSoon";
import InstaFormComponent from "./InstaFormComponent";
import FacebookFormComponent from "./FacebookFormComponent";
import PlaystoreFormComponent from "./PlaystoreFormComponent";
import AmazonFormComponent from "./AmazonFormComponent";
import ZomatoFormComponent from "./ZomatoFormComponent";
import YoutubeFormComponent from "./YoutubeFormComponent";
import AppstoreFormComponent from "./AppstoreFormComponent";
import SpotifyFormComponent from "./SpotifyFormComponent";
import TwitterFormComponent from "./TwitterFormComponent";
import LinkedinFormComponent from "./LinkedinFormComponent";

const StoreFormComponent = () => {
  // const [category, setCategory] = useState("Category");
  const [category, setCategory] = useState("Map");
  const [ShowCategory, setShowCategory] = useState(false);
  // const [store, setStore] = useState("");

  return (
    <div>

      <div className="store-form">
        <h1 className="store-form-category" onClick={(e) => setShowCategory(!ShowCategory)} >
          {
            // category === "Category" ? (
            //   <div className="store-form-category-option">
            //     <div className="store-form-category-option-icon">
            //       <img src={storeIcon} alt="icon" title="icon" />
            //     </div>
            //     <div className="store-form-category-option-text">
            //       All Categories
            //     </div>
            //   </div>
            // ) :
            category === "Map" ? (
              <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={map} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    Google Map 5 Stars&nbsp;
                    <span>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>&nbsp;
                    </span>
                    Reviews
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
            ) : category === "Facebook" ? (
              <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={facebook} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    Facebook Premium Services&nbsp;
                    <span><i class="fa-solid fa-user-plus"></i></span>
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
            ) : category === "Instagram" ? (
              <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={instagram} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                  Instagram Premium Services&nbsp;
                    <span>
                    <i class="fa-solid fa-user-plus"></i>
                    </span>
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
            ) : category === "Youtube" ? (
              <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={youtube} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                  YouTube Premium Service&nbsp;
                    <span>
                    <i class="fa-solid fa-user-plus"></i>
                    </span>
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
            ) : category === "Amazon" ? (
              <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={amazon} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    Amazon 5 Stars 
                    <span>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                    </span>
                    &nbsp; Product Rating
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
            ) : category === "Zomato" ? (
              <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={zomato} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    Zomato 5 Stars
                    <span>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                    </span>
                    &nbsp;Rating
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
            ) : category === "Playstore" ? (
              <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={playstore} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    PlayStore 5 Stars
                    <span>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                    </span>
                    &nbsp;App Review
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
            ) 
            : category === "Appstore" ? (
              <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={appstore} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    AppStore 5 Stars
                    <span>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                    </span>
                    &nbsp;App Review
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
            ) 
            // -----------------------------------------------------------
            : category === "Spotify" ? (
              <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={spotify} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                  Spotify Premium Service&nbsp;
                    {/* <span>
                    <i class="fa-solid fa-user-plus"></i>
                    </span> */}
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
            ) 
            : category === "X" ? (
              <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={x} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                  X / Twitter Premium Service&nbsp;
                    {/* <span>
                    <i class="fa-solid fa-user-plus"></i>
                    </span> */}
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
            ) 
            : category === "Linkedin" ? (
              <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={linkedin} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                  LinkedIn Premium Service&nbsp;
                    {/* <span>
                    <i class="fa-solid fa-user-plus"></i>
                    </span> */}
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
            ) 
            : (
              ""
            )
          }
        </h1>

        {ShowCategory ? (
          <div className="store-form-category-show-option">
            <div
              onClick={(e) => {
                setCategory("Map");
                setShowCategory(false);
              }}
            >
              Google Map 5 Stars Reviews
            </div>

            <div
              onClick={(e) => {
                setCategory("Instagram");
                setShowCategory(false);
              }}
            >
              Instagram Premium Services
            </div>
            
            <div
              onClick={(e) => {
                setCategory("Facebook");
                setShowCategory(false);
              }}
            >
              Facebook Premium Services
            </div>
 
            <div
              onClick={(e) => {
                setCategory("Youtube");
                setShowCategory(false);
              }}
            >
              YouTube Premium Services
            </div>
            
            {/* <div
              onClick={(e) => {
                setCategory("Amazon");
                setShowCategory(false);
              }}
            >
              Amazon 5 Stars Product Rating
            </div>  */}
            
            <div
              onClick={(e) => {
                setCategory("Zomato");
                setShowCategory(false);
              }}
            >
              
Zomato 5 Stars Rating
            </div>
           
            <div
              onClick={(e) => {
                setCategory("Playstore");
                setShowCategory(false);
              }}
            >
              PlayStore 5 Stars App Review
            </div>

           
            
            <div
              onClick={(e) => {
                setCategory("Appstore");
                setShowCategory(false);
              }}
            >
              Appstore 5 Stars App Review
            </div> 
            
            {/* ---------------------------------------------------- */}
            <div
              onClick={(e) => {
                setCategory("Spotify");
                setShowCategory(false);
              }}
            >
              Spotify Premium Services
            </div> 

            <div
              onClick={(e) => {
                setCategory("X");
                setShowCategory(false);
              }}
            >
              X / Twitter Premium Services
            </div> 

            <div
              onClick={(e) => {
                setCategory("Linkedin");
                setShowCategory(false);
              }}
            >
              Linkedin Premium Services
            </div> 


          </div>
        ) : (
          ""
        )}
        <div className="store-form-content">

{
  category === "Map"?
<GoogleFormComponent/>
  :
  category === "Instagram"?
  <InstaFormComponent/>
  :
  category === "Facebook"?
  <FacebookFormComponent/>
  :
  category === "Youtube"?
  <YoutubeFormComponent/>
  :
  category === "Playstore"?
  <PlaystoreFormComponent/>
  :
  category === "Appstore"?
  <AppstoreFormComponent/>
  :
  category === "Amazon"?
  <AmazonFormComponent/>
  :
  category === "Zomato"?
  <ZomatoFormComponent/>
  :
  category === "Spotify"?
  <SpotifyFormComponent/>
  :
  category === "X"?
  <TwitterFormComponent />
  :
  category === "Linkedin"?
  <LinkedinFormComponent />
  :
  <FormCommingSoon/>

}
          {/* <GoogleHeaderComponent/> */}
          
        </div>
      </div>

    </div>
  );
};

export default StoreFormComponent;
