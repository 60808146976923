import React from "react";
import { Link } from "react-router-dom";

const FooterComponent = () => {
  return (
    <div className="footer">
    <div>
      <div className="footer-content">

        <div className="footer-col-1">
          <div className="footer-col-1-logo"><Link to="/">Feed Backer</Link></div>
          <h2 className="footer-col-1-text">
          Buying reviews is against the policies of Google and other review platforms. We do not endorse or engage in the practice of purchasing reviews.
          </h2>
        </div>

        <div className="footer-col-2">
          <div className="footer-col-2-col">
            <div className="footer-col-2-col-heading">CONTACT</div>
            <div className="footer-col-2-col-points">
              <div>
                <span>
                <i class="fa-solid fa-globe"></i>
                </span>{" "}
                feedbacker.in
                
              </div>
              <div>
                <span>
                  <i class="fa-solid fa-phone-volume"></i>
                </span>
                +91 9871-599-433
              </div>
              <div>
                <span>
                  <i class="fa-solid fa-envelope"></i>
                </span>
                feedbacker@gmail.com
              </div>
              <div>
                <span>
                  <i class="fa-brands fa-whatsapp"></i>
                </span>
                +91 9871-599-433
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-cc">
      Copyright <i className="fa-regular fa-copyright"></i> 2024 Feed Backer. All rights reserved.
      </div>

    </div>
    </div>
  );
};

export default FooterComponent;
