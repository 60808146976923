import React, { useState } from "react";

import view from "../media/images/in-view.jpg";
import like from "../media/images/in-like.jpg";
import follower from "../media/images/in-follow.jpg";
import share from "../media/images/in-share.jpg";


import icon from "../media/images/icons/linkedin.png";
import LinkedinFollowersFormComponent from "./form/LinkedinFollowersFormComponent";
import LinkedinViewsFormComponent from "./form/LinkedinViewsFormComponent";
import LinkedinLikesFormComponent from "./form/LinkedinLikesFormComponent";
import LinkedinShareFormComponent from "./form/LinkedinShareFormComponent";


const LinkedinFormComponent = () => {

    
    const [type, setType] = useState("Post Views")
  const [ShowCategory, setShowCategory] = useState(false);
 


  
  return (
    <div>
      
      <div className="header">
        <div className="header-col-2">
          <div className="header-col-2-category" onClick={(e) => setShowCategory(!ShowCategory)}>
          <div className="store-form-category-option">
                <div>
                  <div className="store-form-category-option-icon">
                    <img src={icon} alt="icon" title="icon" />
                  </div>
                  <div className="store-form-category-option-text">
                    LinkedIn&nbsp;
                    <span>{type}&nbsp;</span>
                  </div>
                </div>

                <div className="store-form-category-option-icon">
                  {ShowCategory ? (
                    <i class="fa-solid fa-circle-xmark"></i>
                  ) : (
                    <i class="fa-solid fa-caret-down"></i>
                  )}
                </div>
              </div>
          </div>

          {ShowCategory ? (
            <div className="store-form-category-show-option">

              
              
<div
                onClick={(e) => {
                  setType("Post Views");
                  setShowCategory(false);
                }}
              >
                Linkedin Post Views
              </div>
              
              <div
                onClick={(e) => {
                  setType("Post Likes");
                  setShowCategory(false);
                }}
              >
                Linkedin Post Likes
              </div>
              
              <div
                onClick={(e) => {
                  setType("Page & Profile Followers");
                  setShowCategory(false);
                }}
              >
                Linkedin Profile & Page Followers
              </div>
              
              <div
                onClick={(e) => {
                  setType("Post Share");
                  setShowCategory(false);
                }}
              >
                Linkedin Post Share
              </div>
              
              
              
            </div>
          ) : (
            ""
          )}
          <div className="header-col-2-heading"><>Linked <span>{type} </span></>    
          </div>

          <div>


          {
                type === "Page & Profile Followers"?
                <LinkedinFollowersFormComponent />
                :type === "Post Views"?
                <LinkedinViewsFormComponent/>
                :type === "Post Likes"?
                <LinkedinLikesFormComponent />
                :type === "Post Share"?
                <LinkedinShareFormComponent />
                :""
            }


            
          </div>
        </div>

        <div className="header-col-1">

            {
                type === "Page & Profile Followers"?
                <img src={follower} alt="logo" title="logo" />
                :type === "Post Views"?
                <img src={view} alt="logo" title="logo" />
                :type === "Post Likes"?
                <img src={like} alt="logo" title="logo" />
                :type === "Post Share"?
                <img src={share} alt="logo" title="logo" />
                :""
            }

        </div>
      </div>
    </div>
  );
};

export default LinkedinFormComponent;
 