import React from 'react'

const FormCommingSoon = () => {
  return (
    <div>
        <br />
        {/* <h2>Service Available Soon</h2> */}
        <h2>Under maintenance</h2>
        <br />
        <br />
    </div>
  )
}

export default FormCommingSoon